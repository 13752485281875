/** @format */

import { TUI } from "../declarations/maps/ui";
import { TActionType } from "../declarations/actionTypes";
import {
  TActionAppLoadingFailure,
  TActionAppLoadingRequest,
  TActionAppLoadingSuccess,
} from "../declarations/actions/ui";
import { TActionLoginFailure, TActionLoginReset } from "../declarations/actions/auth";

type Actions =
  | TActionLoginReset
  | TActionLoginFailure
  | TActionAppLoadingRequest
  | TActionAppLoadingSuccess
  | TActionAppLoadingFailure;

export const reducerUI = (prevStore: TUI = {}, action: Actions): TUI => {
  switch (action.type) {
    case TActionType.loginFailure:
      return { ...prevStore, loginValidation: action.payload };
    case TActionType.loginReset:
      return { ...prevStore, loginValidation: {} };
    case TActionType.appLoadingRequest:
      return { ...prevStore, loading: true };
    case TActionType.appLoadingSuccess:
      return { ...prevStore, loading: false };
    case TActionType.appLoadingFailure:
      return { ...prevStore, loading: false };
    default:
      return prevStore;
  }
};
