/** @format */

export const API_PARAMS = {
  BASE_URL: process.env.REACT_APP_API_URL,
};
console.log("@API_BASE_URL@ " + process.env.REACT_APP_API_URL);

export enum API_ENDPOINTS {
  CSRF_TOKEN = "/sanctum/csrf-cookie",
  LOGIN = "/login",
  LOGOUT = "/logout",
  USERS_LIST = "/users",
  USER_AVATAR = "users/:id_user/avatar",
  ROLES = "/roles",
  GROUPS = "/groups",
  LOCATIONS = "/offices",
  NOTICES = "/notices",
  DOCUMENTS = "/documents",
  DOCUMENT_TYPES = "/document-types",
  UPDATE_STATUS = "/update/status/",
  GET_ESTIMATE = "/estimate",
  GET_UNAVAILABITIES = "/unavailabilities/",
  GET_PERIODS = "/periods/",
  SAVE_PLANNING = "/save_planning",
}
