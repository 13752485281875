/** @format */

import { UIRow, UICol, UICard, UIImage, UIText } from "@rstore-react-packages/template-ui";
import React, { FC, useEffect, useState } from "react";
import logo from "../../../../assets/images/logo.png";
import { Colors } from "../../../../constants/Colors";
import { Steps, Button, Form, Spin } from "antd";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import apiClient from "../../../../utils/apiClient";
import { API_ENDPOINTS } from "../../../../constants/api";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../../utils/Routes";
import styled from "styled-components";

import moment from "moment";
import "moment/locale/it-ch";

moment.locale("it-ch");

const { Step } = Steps;

interface Props {
  customerId: string;
  estimateId: string;
  estimate: { [key: string]: unknown };
}

const ViewPlanning: FC<Props> = ({ customerId, estimateId, estimate }: Props): JSX.Element => {
  const [current, setCurrent] = useState(0);
  const [currentForm, setCurrentForm] = useState({});
  const [customerRecallRequest, setCustomerRecallRequest] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();
  const [period, setPeriod] = useState("");
  const [isAlertPeriodVisible, setIsAlertPeriodVisible] = useState<boolean>(false);
  const [team, setTeam] = useState('');
  const [datesForBe, setDatesForBe] = useState([]);


  // @ts-ignore
  const altroSetter = (e: unknown) => setDatesForBe(e);

  useEffect(
    () => {
      apiClient.post(
        API_ENDPOINTS.UPDATE_STATUS,
        JSON.stringify({
          customer_id: customerId,
          estimate_id: estimateId,
          status_id: process.env.REACT_APP_RESERVATION_STATUS_DA_PROGRAMMARE,
          send_mail: 0,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const next = (): void => {
    if (current === 0) {
      //@ts-ignore
      if (currentForm.name && currentForm.surname && currentForm.vat && currentForm.address) {
        setCurrent(current + 1);
      } else {
        form.validateFields();
      }
    } else if (current === 1) {
      //@ts-ignore
      if (!!period || !!customerRecallRequest) {
        setCurrent(current + 1);
      } else if (!!!period && !!customerRecallRequest) {
        setCurrent(current + 1);
      }
    }
  };

  const prev = (): void => {
    setCurrent(current - 1);
    if (current === 2) {
      setCustomerRecallRequest(false);
    }
  };

  const steps = [
    {
      title: "Dati Cliente",
      content: <Step1 />,
      step: 1,
    },
    {
      title: "Programmazione Intervento",
      content: (
        <Step2
          estimate={estimate}
          next={next}
          isAlertPeriodVisible={isAlertPeriodVisible}
          handlePeriodSelected={setPeriod}
          selectedPeriod={period}
          handleIsAlertPeriodVisible={setIsAlertPeriodVisible}
          handleCustomerRecallRequest={setCustomerRecallRequest}
          handleSetTeam={setTeam}
          setDatesForBe={altroSetter}
        />
      ),
      step: 2,
    },
    {
      title: "Riepilogo",
      content: (
        <Step3
          datiForm={currentForm}
          estimate={estimate}
          customerRecallRequest={customerRecallRequest}
          period={period}
        />
      ),
      step: 3,
    },
  ];

  const onValuesChange = (editedValue: any, allValues: any): void => {
    setCurrentForm(allValues);
  };

  const onFinish = (e: any): void => {
    setLoading(true);
    console.log(moment(datesForBe[0][0]))
    console.log(moment(period.split(" - ")[0],'DD/MM/YYYY').format('YYYY-MM-DD'));
    console.log(
      datesForBe.find(
        e => e[0] == moment(period.split(" - ")[0], "DD/MM/YYYY").format("YYYY-MM-DD"),
      ),
    );
    
    e.period = period;
    e.customerRecallRequest = customerRecallRequest;
    e.estimate_id = estimateId;
    e.teamId = team;
    e.final_period = datesForBe.find(
      e => e[0] == moment(period.split(" - ")[0], "DD/MM/YYYY").format("YYYY-MM-DD"),
    );
    apiClient.post(API_ENDPOINTS.SAVE_PLANNING, JSON.stringify(e)).then(res => {
      console.log(res);
      if (res.data.code === 200) {
        if (customerRecallRequest) history.push(Routes.datesWithOperator);
        else history.push(Routes.completedPage);
      }
    });
  };

  return (
    <>
      {!!loading && (
        <StyledLoader>
          <Spin style={{ position: "relative", top: "50%" }} />
        </StyledLoader>
      )}
      <UIRow justify="center" style={{ marginTop: "30px" }}>
        <UICol xs={20}>
          <UICard>
            <UIRow style={{ marginTop: "30px" }}>
              <UICol xs={24}>
                <UIImage src={logo} objectFit="contain" imgHeight="120px" />
              </UICol>
            </UIRow>
            <UIRow>
              <UICol
                xs={24}
                style={{ textAlign: "center", marginTop: "30px", marginBottom: "30px" }}
              >
                <UIText color={Colors.customGray} strong>
                  Gentile Cliente, completa il form di prenotazione per programmare il preventivo.
                </UIText>{" "}
                <br /> <br />
              </UICol>
            </UIRow>
            <Form
              form={form}
              name="formPrenotazioni"
              onFinish={onFinish}
              layout="vertical"
              onValuesChange={onValuesChange}
            >
              <UIRow>
                <UICol xs={24}>
                  <Steps current={current}>
                    {steps.map(item => (
                      <Step key={item.title} title={item.title} />
                    ))}
                  </Steps>
                  {steps.map(
                    (item): JSX.Element => (
                      <div
                        key={item.step}
                        className={`steps-content`}
                        style={{ display: item.step !== current + 1 ? "none" : "" }}
                      >
                        {item.content}
                      </div>
                    ),
                  )}
                  <div className="steps-action">
                    {current > 0 && current !== steps.length - 1 && (
                      <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                        Indietro
                      </Button>
                    )}
                    {current < steps.length - 1 && (
                      <Button type="primary" onClick={() => next()}>
                        Avanti
                      </Button>
                    )}

                    {current === steps.length - 1 && (
                      <Form.Item>
                        <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                          Indietro
                        </Button>
                        <Button type="primary" htmlType="submit">
                          Invia
                        </Button>
                      </Form.Item>
                    )}
                  </div>
                </UICol>
              </UIRow>
            </Form>
          </UICard>
        </UICol>
      </UIRow>
    </>
  );
};

const StyledLoader = styled.div`
  text-align: center;
  background: rgba(255, 255, 255, 0.589);
  height: 100vh;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

export default ViewPlanning;
