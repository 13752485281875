/** @format */

export enum Routes {
  home = "/",
  login = "/login",
  settings = "/configurazione",
  confirmPage = "/confirm",
  completedPage = "/completed",
  donePage = "/done",
  errorPage = "/error",
  datesWithOperator = "/datesWithOperator"
}
