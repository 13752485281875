/** @format */

import React from "react";
import "./App.less";
import { Switch } from "react-router-dom";
import { RouteGuest } from "./components/utils/RouteGuest";
import { Routes } from "./utils/Routes";
import ViewHome from "./components/views/ViewHome";
import ViewConfirm from './components/views/ViewConfirm/index';
import ViewCompleted from "./components/views/ViewCompleted";
import ViewDone from "./components/views/ViewDone";
import ViewError from "./components/views/ViewError";
import ViewDatesWithOperator from "./components/views/ViewDatesWithOperator";

function App() {
  return (
    <Switch>
      <RouteGuest path={Routes.home} exact component={ViewHome} />
      <RouteGuest path={Routes.confirmPage} exact component={ViewConfirm} />
      <RouteGuest path={Routes.completedPage} exact component={ViewCompleted} />
      <RouteGuest path={Routes.donePage} exact component={ViewDone} />
      <RouteGuest path={Routes.errorPage} exact component={ViewError} />
      <RouteGuest path={Routes.datesWithOperator} exact component={ViewDatesWithOperator} />
    </Switch>
  );
}

export default App;