/** @format */

import { UICol, UIInput, UIRow } from "@rstore-react-packages/template-ui";
import { Form } from "antd";

const Step1 = () => {
  return (
    <>
      <UIRow style={{ marginTop: "30px" }} justify="space-between">
        <UICol xs={10}>
          <Form.Item
            label="Nome"
            name="name"
            rules={[{ required: true, message: "Nome obbligatorio!" }]}
          >
            <UIInput />
          </Form.Item>
        </UICol>
        <UICol xs={10}>
          <Form.Item
            label="Cognome"
            name="surname"
            rules={[{ required: true, message: "Cognome obbligatorio!" }]}
          >
            <UIInput />
          </Form.Item>
        </UICol>
      </UIRow>
      <UIRow style={{ marginTop: "30px" }} justify="space-between">
        <UICol xs={10}>
          <Form.Item
            label="Residenza"
            name="address"
            rules={[{ required: true, message: "Residenza obbligatorio!" }]}
          >
            <UIInput />
          </Form.Item>
        </UICol>
        <UICol xs={10}>
          <Form.Item
            label="Cod.Fiscale/P.Iva"
            name="vat"
            rules={[{ required: true, message: "Partita IVA obbligatoria!" }]}
          >
            <UIInput />
          </Form.Item>
        </UICol>
      </UIRow>
    </>
  );
};

export default Step1;
