/** @format */

import React, { FC, useEffect, useState } from "react";
import { Modal, Button, Radio } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import { UICol, UIRow, UIText } from "@rstore-react-packages/template-ui";
import { Colors } from "../../../../../constants/Colors";
import apiClient from "../../../../../utils/apiClient";
import { API_ENDPOINTS } from "../../../../../constants/api";

import moment from "moment";
import "moment/locale/it-ch";

moment.locale("it-ch");

interface Props {
  estimate: { [key: string]: unknown };
  next: () => void;
  handlePeriodSelected: (props: string) => void;
  handleSetTeam: (props: string) => void;
  handleIsAlertPeriodVisible: (props: boolean) => void;
  selectedPeriod: string;
  handleCustomerRecallRequest: (props: boolean) => void;
  isAlertPeriodVisible: boolean;
  setDatesForBe: (props: unknown) => void;
}

type TPeriod = string[];

const Step2: FC<Props> = ({
  estimate,
  next,
  setDatesForBe,
  handlePeriodSelected,
  handleSetTeam,
  selectedPeriod,
  isAlertPeriodVisible,
  handleIsAlertPeriodVisible,
  handleCustomerRecallRequest,
}: Props): JSX.Element => {
  const [dates, setDates] = useState<TPeriod[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    apiClient.get(API_ENDPOINTS.GET_PERIODS + `${estimate.id}`).then(res => {
      if (!!res.data[0]) {
        setDates(res.data[0].periods.map(ev => [ev[0], ev[ev.length - 1]]));
        setDatesForBe(res.data[0].periods);
        //handleSetTeam("" + Object.keys(res.data).find(e => !!Object.values(e).length));
        handleSetTeam("" + res.data[0].id);
      }
    });
  }, []);

  useEffect(() => {
    console.log(dates);
    console.log(Object.values(dates).find(e => !!Object.values(e).length) || []);
  }, [dates]);

  if (!Object.values(dates).find(e => !!Object.values(e).length))
    return (
      <>
        <UIRow style={{ marginTop: "40px" }} align="middle">
          <UICol xs={24} style={{ textAlign: "center", marginBottom: "20px" }}>
            <UIText>
              Non ci sono date e squadre disponibili a lavorare il preventivo. Contatta Interventi
              in Fune per concordare la pianificazione degli interventi
            </UIText>
          </UICol>
        </UIRow>
      </>
    );

  const periodArray = Object.values(dates).find(e => !!Object.values(e).length) || [];

  return (
    <>
      <Modal
        title="Ricontatto telefonico"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => {
          handleCustomerRecallRequest(true);
          setIsModalVisible(false);
          next();
        }}
      >
        <UIRow>
          <UICol xs={24} style={{ textAlign: "center" }}>
            <QuestionCircleOutlined
              style={{ fontSize: "34px", marginBottom: "10px", color: Colors.customBlue }}
            />{" "}
            <br />
            Preferisci essere contattato da un nostro operatore <br /> per pianificare insieme le
            date dell'intervento?
          </UICol>
        </UIRow>
      </Modal>

      <UIRow style={{ marginTop: "40px" }} align="middle">
        <UICol xs={24} style={{ textAlign: "center", marginBottom: "20px" }}>
          <UIText>Scegli uno dei tre periodi disponibili per iniziare e completare i lavori</UIText>
        </UICol>
        <UICol xs={24} style={{ textAlign: "center", marginBottom: "20px", width: "50%" }}>
          <UIRow style={{ display: "flex", justifyContent: "space-around" }}>
            <UICol>INIZIO</UICol>
            <UICol>FINE</UICol>
          </UIRow>
        </UICol>

        <UICol style={{ textAlign: "center" }} xs={24}>
          <UIRow>
            <Radio.Group
              defaultValue={false}
              buttonStyle="solid"
              style={{ width: "100%" }}
              onChange={ev => {
                handlePeriodSelected(ev.target.value);
                handleIsAlertPeriodVisible(false);
              }}
            >
              <>
                {!!Object.values(dates).length &&
                  Object.values(dates).map((e, i) => {
                    return (
                      <div key={i} style={{ width: "100%", marginBottom: "30px" }}>
                        <Radio.Button
                          style={{ width: "75%" }}
                          value={
                            moment(e[0]).format("DD/MM/YYYY") +
                            " - " +
                            moment(e[e.length - 1]).format("DD/MM/YYYY")
                          }
                          key={i}
                        >
                          <UIRow style={{ display: "flex", justifyContent: "space-around" }}>
                            <UICol>{moment(e[0]).format("DD/MM/YYYY")}</UICol>
                            <UICol>SCEGLI PERIODO</UICol>
                            <UICol>{moment(e[e.length - 1]).format("DD/MM/YYYY")}</UICol>
                          </UIRow>
                        </Radio.Button>
                      </div>
                    );
                  })}
              </>
            </Radio.Group>
          </UIRow>
        </UICol>
        <UICol style={{ textAlign: "center" }} xs={24}>
          {!!isAlertPeriodVisible && (
            <UIText
              style={{
                backgroundColor: "red",
                color: "white",
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              Devi selezionare un periodo!
            </UIText>
          )}
        </UICol>
      </UIRow>

      <UIRow style={{ marginTop: "40px" }} align="middle">
        <UICol style={{ textAlign: "center" }} xs={24}>
          <Button onClick={() => setIsModalVisible(true)}>Scegli date con operatore</Button>
        </UICol>
      </UIRow>
    </>
  );
};

export default Step2;
