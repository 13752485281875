/** @format */

import React, { FC } from "react";
import { UICol, UIRow, UIText } from "@rstore-react-packages/template-ui";
import { Colors } from "../../../../../constants/Colors";
interface Props {
  datiForm: { [key: string]: unknown };
  estimate: { [key: string]: unknown };
  customerRecallRequest: boolean;
  period: string;
}

const Step3: FC<Props> = ({
  datiForm,
  estimate,
  customerRecallRequest,
  period,
}: Props): JSX.Element => {
  return (
    <>
      <UIRow style={{ marginTop: "30px" }}>
        <UICol xs={4}>
          <UIText color={Colors.customGray} strong>
            Nome
          </UIText>
        </UICol>
        <UICol xs={8}>
          <UIText color={Colors.customGray}> {datiForm.name} </UIText>
        </UICol>
        <UICol xs={4}>
          <UIText color={Colors.customGray} strong>
            N.Preventivo
          </UIText>
        </UICol>
        <UICol xs={4}>
          <UIText color={Colors.customGray}> {estimate.code}</UIText>
        </UICol>
      </UIRow>

      <UIRow style={{ marginTop: "30px" }}>
        <UICol xs={4}>
          <UIText color={Colors.customGray} strong>
            Cognome
          </UIText>
        </UICol>
        <UICol xs={8}>
          <UIText color={Colors.customGray}> {datiForm.surname}</UIText>
        </UICol>
        <UICol xs={4}>
          <UIText color={Colors.customGray} strong>
            Periodo
          </UIText>
        </UICol>
        <UICol xs={5}>
          <UIText color={Colors.customGray}>
            {customerRecallRequest ? "Da programmare con operatore" : period}
          </UIText>
        </UICol>
      </UIRow>

      <UIRow style={{ marginTop: "30px" }}>
        <UICol xs={4}>
          <UIText color={Colors.customGray} strong>
            C.Fiscale/P. IVA
          </UIText>
        </UICol>
        <UICol xs={8}>
          <UIText color={Colors.customGray}> {datiForm.vat}</UIText>
        </UICol>
        <UICol xs={4}>
          <UIText color={Colors.customGray} strong>
            Totale Preventivo
          </UIText>
        </UICol>
        <UICol xs={4}>
          <UIText color={Colors.customGray}>
            {" "}
            € {parseInt(estimate.total as string).toFixed(2)}
          </UIText>
        </UICol>
      </UIRow>

      <UIRow style={{ marginTop: "30px", marginBottom: "30px" }}>
        <UICol xs={4}>
          <UIText color={Colors.customGray} strong>
            Residenza
          </UIText>
        </UICol>
        <UICol xs={8}>
          <UIText color={Colors.customGray}> {datiForm.address}</UIText>
        </UICol>
      </UIRow>

      <UIRow style={{ marginTop: "30px" }}>
        <UICol xs={8}>
          <UIText color={Colors.customBlue} strong>
            {" "}
            Note e Penali
          </UIText>
        </UICol>
      </UIRow>
      <UIRow style={{ marginTop: "10px", marginBottom: "70px" }}>
        <UICol xs={24}>
          <UIText color={Colors.customGray}>
            {" "}
            Accetto i termini e le condizioni autorizzando il trattamento dei miei dati personali ai
            sensi dell'informativa sulla privacy
          </UIText>
        </UICol>
      </UIRow>
    </>
  );
};

export default Step3;
