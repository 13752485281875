/** @format */

import { watcherAuth } from "./watcherAuth";
import { watcherCache } from "./watcherCache";
/** @format */

export const watchers = [
  watcherAuth,
  watcherCache,
];
