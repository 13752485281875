import { UIRow, UICol, UICard, UIImage, UITitle, UIText } from '@rstore-react-packages/template-ui';
import React from 'react'
import logo from "../../../assets/images/logo.png";
import { Colors } from '../../../constants/Colors';

const ViewError = () => {
    return (
      <UIRow justify="center" style={{ marginTop: "30px" }}>
        <UICol xs={20}>
          <UICard>
            <UIRow style={{ marginTop: "30px" }}>
              <UICol xs={24}>
                <UIImage src={logo} objectFit="contain" imgHeight="120px" />
              </UICol>
            </UIRow>
            <UIRow justify="center">
              <UICol xs={18} style={{ textAlign: "center", marginTop: "60px" }}>
                <UITitle level={5} color={Colors.customGray}>
                  Errore!
                </UITitle>{" "}
                <br /> <br />
              </UICol>
            </UIRow>
            <UIRow justify="center">
              <UICol xs={18} style={{ textAlign: "center" }}>
                <UIText color={Colors.customGray} strong>
                  Pagina non trovata
                </UIText>{" "}
              </UICol>
            </UIRow>
          </UICard>
        </UICol>
      </UIRow>
    );
}

export default ViewError
