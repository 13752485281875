/** @format */

import { UIRow, UICol, UICard, UIImage, UITitle, UIColors, UIText } from "@rstore-react-packages/template-ui";
import React from "react";
import { Colors } from "../../../constants/Colors";
import { CheckCircleOutlined } from "@ant-design/icons";
import logo from '../../../assets/images/logo.png'

const ViewCompleted = () => {
  return (
    <>
      <UIRow justify="center" style={{ marginTop: "30px" }}>
        <UICol xs={20}>
          <UICard>
            <UIRow style={{ marginTop: "30px" }}>
              <UICol xs={24}>
                <UIImage src={logo} objectFit="contain" imgHeight="120px" />
              </UICol>
            </UIRow>
            <UIRow justify="center">
              <UICol xs={18} style={{ textAlign: "center", marginTop: "60px" }}>
                <UITitle level={5} color={Colors.customGray}>
                  <CheckCircleOutlined
                    style={{ color: UIColors.blue, marginRight: "10px", fontSize: "26px" }}
                  />
                  Intervento Pianificato!
                </UITitle>{" "}
                <br /> <br />
              </UICol>
            </UIRow>
            <UIRow justify="center">
              <UICol xs={18} style={{ textAlign: "center" }}>
                <UIText color={Colors.customGray} strong>
                  Gentile Cliente
                </UIText>{" "}
                <br /> <br />
                <UIText color={Colors.customGray} strong>
                  Ti confermiamo di aver programmato la data di inizio dell'intervento.
                </UIText>
                <br />
                <br />
                <UIText color={Colors.customGray} strong>
                  Saluti
                </UIText>
                <br />
                <UIText color={Colors.customGray} strong>
                  Lo Staff di Interventi in Fune
                </UIText>
              </UICol>
            </UIRow>
          </UICard>
        </UICol>
      </UIRow>
    </>
  );
};

export default ViewCompleted;
