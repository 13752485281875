/** @format */

import { combineReducers } from "redux";
import { reducerAuth } from "./reducerAuth";
import { reducerUI } from "./reducerUI";

export const reducers = combineReducers({
  ui: reducerUI,
  auth: reducerAuth,
});
