/** @format */

import React, { FC } from "react";
import logo from "../../../logo.svg";

const ViewHome: FC = (): JSX.Element => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" width={100} alt="logo" />
        <h3 className="Welcome-title">
          Benvenuto sul Template React di <span className="Rstore-name">RStore</span>
        </h3>
        <div className="Istructions-box">
          <p className="Istructions-title">## Istruzioni ##</p>
          <p>
            Questo file (/src/App.tsx) è il file principale della tua applicazione. Modificalo per
            iniziare a lavorare sul tuo progetto.
          </p>
          <br />
          <p>
            Per implementare il <strong>pacchetto grafico di RStore</strong> procedi così:
          </p>
          <p>
            Nella root (/) del tuo progetto crea il file "
            <span style={{ color: "red" }}>.npmrc</span>" e inserisci:
          </p>
          <i className="Instructions-code">
            @rstore-react-packages:registry=https://git.infcom.it/api/v4/packages/npm/
          </i>
          <br />
          <i className="Instructions-code">
            {`//git.infcom.it/api/v4/packages/npm/:_authToken=<TOKEN_GITLAB>
        `}
          </i>
          <br />
          <br />
          <p>E da terminale esegui:</p>
          <i className="Instructions-code">npm i @rstore-react-packages/template-ui</i>
          <br />
          <br />
          <p className="Istructions-salute">Buon lavoro!</p>
        </div>
      </header>
    </div>
  );
};

export default ViewHome;
