/** @format */

import {
  UICard,
  UICol,
  UIColors,
  UIImage,
  UIRow,
  UIText,
  UITitle,
} from "@rstore-react-packages/template-ui";
import React, { FC, useEffect } from "react";
import logo from "../../../../assets/images/logo.png";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Colors } from "../../../../constants/Colors";
import apiClient from "../../../../utils/apiClient";
import { API_ENDPOINTS } from "../../../../constants/api";


interface Props {
  customerId: string;
  estimateId: string;
}

const ViewNotPlanning: FC<Props> = ({ customerId, estimateId }: Props): JSX.Element => {
  useEffect(
    () => {
      apiClient.post(API_ENDPOINTS.UPDATE_STATUS, {
        customer_id: customerId,
        estimate_id: estimateId,
        status_id: process.env.REACT_APP_RESERVATION_STATUS_DA_PROGRAMMARE,
        send_mail: 1,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <>
      <UIRow justify="center" style={{ marginTop: "30px" }}>
        <UICol xs={20}>
          <UICard>
            <UIRow style={{ marginTop: "30px" }}>
              <UICol xs={24}>
                <UIImage src={logo} objectFit="contain" imgHeight="120px" />
              </UICol>
            </UIRow>
            <UIRow justify="center">
              <UICol xs={18} style={{ textAlign: "center", marginTop: "60px" }}>
                <UITitle level={5} color={Colors.customGray}>
                  <CheckCircleOutlined
                    style={{ color: UIColors.blue, marginRight: "10px", fontSize: "26px" }}
                  />
                  Preventivo Accettato!
                </UITitle>{" "}
                <br /> <br />
              </UICol>
            </UIRow>
            <UIRow justify="center">
              <UICol xs={18} style={{ textAlign: "center" }}>
                <UIText color={Colors.customGray} strong>
                  Gentile Cliente
                </UIText>{" "}
                <br /> <br />
                <UIText color={Colors.customGray} strong>
                  Ti ricontatteremo il prima possibile per pianificare insieme la programmazione
                  dell'intervento.
                </UIText>
                <br />
                <UIText color={Colors.customGray} strong>
                  Ti ringraziamo per aver accettato la nostra offerta.
                </UIText>{" "}
                <br />
                <br />
                <UIText color={Colors.customGray} strong>
                  Saluti
                </UIText>
                <br />
                <UIText color={Colors.customGray} strong>
                  Lo Staff di Interventi in Fune
                </UIText>
              </UICol>
            </UIRow>
          </UICard>
        </UICol>
      </UIRow>
    </>
  );
};

export default ViewNotPlanning;
