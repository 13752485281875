/** @format */

import React, { FC, useEffect, useState } from "react";
import ViewPlanning from "./partials/ViewPlanning";
import ViewNotPlanning from "./partials/ViewNotPlanning";
import ViewError from "../ViewError/index";
import apiClient from "../../../utils/apiClient";
import { API_ENDPOINTS } from "../../../constants/api";
import { Spin } from "antd";
import { UICol, UIRow } from "@rstore-react-packages/template-ui";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../utils/Routes";

const ViewConfirm: FC = (): JSX.Element => {
  const urlParams = new URLSearchParams(window.location.search);
  const [estimate, setEstimate] = useState({});
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(true);

  const customerId = urlParams.get("customer_id");
  const estimateId = urlParams.get("estimate_id");
  const toPlan = urlParams.get("toplan");

  const history = useHistory();
  useEffect(
    () => {
      apiClient.get(`${API_ENDPOINTS.GET_ESTIMATE}/${customerId}/${estimateId}`).then(res => {
        if (res.data) {
          setEstimate(res.data);
          //rimuovo i loading
          setLoading(false);
          //controllo sullo stato del preventivo
          !!res.data.estimate_status_users.find(
            status =>
              status.status_id ===
              parseInt(process.env.REACT_APP_RESERVATION_STATUS_ID_IN_PROGRAMMAZIONE as string),
          ) && setDone(true);

          !!res.data.estimate_status_users.find(
            status =>
              status.status_id ===
              parseInt(process.env.REACT_APP_RESERVATION_STATUS_ID_PROGRAMMATO as string),
          ) && setDone(true);

        } else {
          history.push(Routes.errorPage);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (loading)
    return (
      <UIRow>
        <UICol xs={24} style={{ marginTop: "200px", textAlign: "center" }}>
          <Spin tip="Loading..." size="large" style={{ fontSize: "2em" }} />
        </UICol>
      </UIRow>
    );

  //nel caso in cui il cliente abbia già visitato la pagina (e quindi lo stato del preventivo sia già stato aggiornato)
  if (!!done) {
    history.push(Routes.donePage);
  }

  //nel caso in cui, in querystring, siano presenti il customer_id, l'estimate_id e il parametro toplan sia = 1 (il cliente sta pianificando l'intervento, sceglierà le date)
  if (!!parseInt(toPlan as string) && !!customerId && !!estimateId) {
    return <ViewPlanning customerId={customerId} estimateId={estimateId} estimate={estimate} />;
  }

  //nel caso in cui, in querystring, siano presenti il customer_id, l'estimate_id e il parametro toplan sia = 0 (dovrà essere pianificato successivamente dall'agente o dall'amministratore, cmq dal backoffice)
  if (!!!parseInt(toPlan as string) && !!customerId && !!estimateId) {
    return <ViewNotPlanning customerId={customerId} estimateId={estimateId} />;
  }

  //In tutti gli altri casi
  return <ViewError />;
};

export default ViewConfirm;
